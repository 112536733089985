<template>
	<div class="explain">
		<div class="title">订购说明</div>
		<div class="con" v-for="(item, index) in serviceList">
			<p>{{ item.name }}：</p>
			<p>{{ item.summary }}</p>
		</div>
	</div>
</template>

<script>
	export default {
		props:{
			serviceList: Array
		},
		data() {
			return {
			}
		},
	}
</script>

<style>
</style>
