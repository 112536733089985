<template>
	<div class="particulars" v-html="goods.content">
	</div>
</template>

<script>
	export default {
		props:{
			goods: Object
		},
		data() {
			return {
			}
		},
	}
</script>

<style lang="scss" scoped>
	.particulars{
		
		img{
			max-width: 100%;
			margin: 0 auto;
			display:block;
			vertival-align:bottom
		}
	}
</style>
