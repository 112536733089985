import request from '@/utils/request'

// api地址
const api = {
  list: 'goods.service/list'
}

// 商品服务列表
export const list = goodsId => {
  return request({
      url: api.list,
      method: 'get',
  	params: { goodsId }
    })
}
