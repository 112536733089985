<template>
	<div class="product-details">
		<!-- 头部 -->
		<mainHeader :cartAdd="cartAdd"></mainHeader>
		<!-- 导航 -->
		<navigation :categoryList="categoryList">
			<template slot="breadcrumb">
				<breadcrumb title="产品分类" routerPath="/productlist" :subtitle="goods.goods_name"></breadcrumb>
			</template>
		</navigation>
		<div class="wrapper">
			<div class="product-top clearfix">
				<carousel :goods="goods"></carousel>
				<information :goods="goods" :couponList="couponList" @addCart="addCart"></information>
			</div>
			<div class="product-con clearfix">
				<hotSale></hotSale>
				<div class="product-con-right">
					<el-tabs class="main-tabs main-tabs2" v-model="activeName" @tab-click="handleClick">
						<el-tab-pane label="产品详情" name="first">
							<particulars :goods="goods"></particulars>
						</el-tab-pane>
						<!-- <el-tab-pane label="评价" name="second">
							<evaluate :goods="goods"></evaluate>
						</el-tab-pane> -->
						<el-tab-pane label="订购说明" name="third">
							<explain :serviceList="serviceList"></explain>
						</el-tab-pane>
						<!-- <el-tab-pane label="售后保障" name="three">
							<afterSales :serviceList="serviceList"></afterSales>
						</el-tab-pane> -->
					</el-tabs>
				</div>
			</div>

		</div>
		<!-- 尾部 -->
		<mainFooter></mainFooter>
	</div>
</template>

<script>
	import * as GoodsApi from '@/api/goods'
	import * as ServiceApi from '@/api/goods/service'
    import * as CouponApi from '@/api/coupon'
	import mainHeader from '../../components/header.vue'
	import navigation from '../../components/navigation.vue'
	import breadcrumb from '../../components/breadcrumb.vue'
	import mainFooter from '../../components/footer.vue'
	import carousel from './components/carousel.vue'
	import information from './components/information.vue'
	import hotSale from './components/hot-sale.vue'
	import particulars from './components/particulars.vue'
	import evaluate from './components/evaluate.vue'
	import explain from './components/explain.vue'
	import afterSales from './components/after-sales.vue'
	import * as CategoryApi from '@/api/category'
	export default {
		components: {
			mainHeader,
			navigation,
			breadcrumb,
			mainFooter,
			carousel,
			information,
			hotSale,
			particulars,
			evaluate,
			explain,
			afterSales
		},
		data() {
			return {
				userId: localStorage.getItem('userId'),
				activeName: 'first',
				// 当前商品ID
				goodsId: null,
				// 商品详情
				goods: {},
				serviceList: [],
				cartAdd: 0,
				categoryList: [],
                couponList: []
			};
		},
		watch: {
			$route (to, from) {
				this.getCategoryList()
				if(this.$route.query.id){
					this.goodsId = this.$route.query.id
				}
				this.getGoods()
				this.getService()
			}
		},
		created() {
			this.getCategoryList()
			if(this.$route.query.id){
				this.goodsId = this.$route.query.id
			}
			this.getGoods()
			this.getService()
			if(this.userId){
				this.getCouponList()
			}
		},
		methods: {
			getCategoryList(){
				CategoryApi.list().then(response => {
				  this.categoryList = response.data.list
				}).catch(err => {
				  console.log(err)
				})
			},
			getGoods(){
				let app = this
				GoodsApi.detail(app.goodsId).then(response => {
				  // console.log(response)
				  app.goods = response.data.detail
				}).catch(err => {
				  console.log(err)
				})
			},
			getService(){
				let app = this
				ServiceApi.list(app.goodsId)
				  .then(result => app.serviceList = result.data.list)
			},
            getCouponList(){
                let app = this
                CouponApi.listByGoodsId(app.goodsId)
                    .then(result => app.couponList = result.data.list)
            },
			addCart(){
				this.cartAdd++
			},
			handleClick(tab, event) {
				console.log(tab, event);
			}
		}

	}
</script>

<style lang="scss">
	@import "../../assets/css/product-details.scss"
</style>
