<template>
	<div class="evaluate">
		<ul>
			<li class="clearfix" v-for="(item,index) in evaluateList" :key="index">
				<div class="user">
					<img :src="item.userimg">
					<p>{{item.user}}</p>
				</div>
				<div class="evaluate-con">
					<el-rate v-model="item.rateVaule" :colors="colors" disabled></el-rate>
					<div class="text">
						{{item.text}}
					</div>
					<div class="picture clearfix">
						<ul>
							<li v-for="(value,i) in item.imgList" :key="i" @click="picture(value,item)">
								<img :src="value.img">
							</li>
						</ul>
					</div>
					<div class="datu" v-if="item.datu">
						<img :src="item.datu">
					</div>
					<div class="time">2021-05-01 18:54:57</div>
				</div>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				evaluateList:[{
					userimg:require('@/assets/img/man.png'),
					datu:"",
					user:"1***8",
					rateVaule: 5,
					text:"车子很不错，日常续航能够超过标称续航，跑高速续航大概少四分之一的样子。外观很满意，开起来各种设置提醒之类的非常贴心，驾驶体验舒服。苏宁服务还是很好的，比较担心日后的保养和维修。总结来说，就是非常值得购买的车车，性价比很好",
					imgList:[{
						img:"https://image.suning.cn/uimg/ZR/share_order/162055194031617584_400x400.jpg"
					},{
						img:"https://imgservice.suning.cn/uimg1/b2c/image/cTo-zxiPZHMPo1Zhs_HW1A.jpg_120w_120h_4e"
					},{
						img:"https://imgservice.suning.cn/uimg1/b2c/image/fE0DllSA3yKMMK4Tn2tfyA.jpg_120w_120h_4e"
					}]
				},{
					userimg:require('@/assets/img/man.png'),
					datu:"",
					user:"1***8",
					rateVaule: 5,
					text:"车子很不错，日常续航能够超过标称续航，跑高速续航大概少四分之一的样子。外观很满意，开起来各种设置提醒之类的非常贴心，驾驶体验舒服。苏宁服务还是很好的，比较担心日后的保养和维修。总结来说，就是非常值得购买的车车，性价比很好",
					imgList:[{
						img:"https://image.suning.cn/uimg/ZR/share_order/162055194031617584_400x400.jpg"
					},{
						img:"https://imgservice.suning.cn/uimg1/b2c/image/cTo-zxiPZHMPo1Zhs_HW1A.jpg_120w_120h_4e"
					},{
						img:"https://imgservice.suning.cn/uimg1/b2c/image/fE0DllSA3yKMMK4Tn2tfyA.jpg_120w_120h_4e"
					}]
				},{
					userimg:require('@/assets/img/man.png'),
					datu:"",
					user:"1***8",
					rateVaule: 5,
					text:"车子很不错，日常续航能够超过标称续航，跑高速续航大概少四分之一的样子。外观很满意，开起来各种设置提醒之类的非常贴心，驾驶体验舒服。苏宁服务还是很好的，比较担心日后的保养和维修。总结来说，就是非常值得购买的车车，性价比很好",
					imgList:[{
						img:"https://image.suning.cn/uimg/ZR/share_order/162055194031617584_400x400.jpg"
					},{
						img:"https://imgservice.suning.cn/uimg1/b2c/image/cTo-zxiPZHMPo1Zhs_HW1A.jpg_120w_120h_4e"
					},{
						img:"https://imgservice.suning.cn/uimg1/b2c/image/fE0DllSA3yKMMK4Tn2tfyA.jpg_120w_120h_4e"
					}]
				}],
				colors: ['#99A9BF', '#F7BA2A', '#F90']
			}
		},
		methods:{
			picture(value,item){
				item.datu = value.img
			}
		}
	}
</script>

<style>
</style>
