<template>
	<div class="information">
		<div class="info-title">{{goods.goods_name}} (编码：{{goods.goods_no}})</div>
		<div class="info-item"><span class="subtitle"><em>备品号</em><i>：</i></span>{{goods.goods_no}}</div>
		<div class="info-item"><span class="subtitle"><em>热销</em><i>：</i></span>{{goods.goods_sales}}件</div>
		<div class="keynote">
			<div class="info-item price">
				<span class="subtitle"><em>价格</em><i>：</i></span>
				<div class="number"><i>￥</i><span>{{goods.goods_price_min}}</span></div>
			</div>
			<!-- <div class="info-item info-coupon">
				<span class="subtitle"><em>优惠券</em><i>：</i></span>
				<ul class="coupon-con">
					<li class="coupon-item" v-for="coupon in couponList" :key="coupon.coupon_id">
						<img src="@/assets/img/coupon-bg01.png" alt="" class="left-icon">
						<div class="text" v-if="coupon.coupon_type == 10">满{{coupon.min_price}}减{{coupon.reduce_price}}</div>
                        <div class="text" v-if="coupon.coupon_type == 20">满{{coupon.min_price}}打{{coupon.discount}}折</div>
						<img src="@/assets/img/coupon-bg02.png" alt="" class="right-icon">
					</li>
				</ul>
			</div>
			<div class="tips" v-if="couponList.length!==0">
				<i class="el-icon-warning-outline"></i>
				优惠券请到
				<router-link to="/myhome/coupon">领券中心</router-link>
				领取才可以使用！
			</div> -->
		</div>
		<!-- <div class="info-item car-model">
			<span class="subtitle"><em>适用车型</em><i>：</i></span>
			<span class="item">SFJ5869</span><span class="item">GHG8996</span><span class="item">HJK5566</span>
		</div>
		<div class="info-item"><span class="subtitle"><em>总库存</em><i>：</i></span>{{goods.stock_total}}</div> -->
		<div class="info-item number">
			<span class="subtitle"><em>数量</em><i>：</i></span>
			<el-input-number size="small" v-model="number" :min="1" class="main-input-number"></el-input-number>
			<em class="unit">件</em>
		</div>
		<div class="storehouse" v-if="goods.spec_type == 20"  v-for="(spec,index) in goods.specList" :key="index">
			<div class="storehouse-title">{{spec.spec_name}}</div>
			<div class="radio-item clearfix" v-for="(value,vindex) in spec.valueList" :key="vindex">
				<div class="left">
					<el-radio :disabled="!goods.skuList[vindex].stock_num" :label="value.spec_value_id" border v-model="goods_sku_id" size="small">{{value.spec_value}}</el-radio>
					<span>库存 {{goods.skuList[vindex].stock_num}} 件</span>
				</div>
				<!-- <div class="right">
					<span>距您 {{item.distance}} km</span>
					<i class="iconfont">&#xe614;</i>
				</div> -->
			</div>
		</div>
		<div class="btn">
			<el-button type="warning" @click="buyNow">立即购买</el-button>
			<el-button type="danger" class="btn-orange" @click="addCart"><i class="iconfont">&#xe604;</i>加入购物车</el-button>
		</div>
	</div>
</template>

<script>
	import * as CartApi from '@/api/cart'
	export default {
		props:{
			goods: Object,
            couponList: Array
		},
		data() {
			return {
				userId: localStorage.getItem('userId'),
				number: "1",
				goods_sku_id: 0
			}
		},
		watch:{
			goods(){
				let app = this
				if(app.goods.spec_type == 20){
					app.goods.skuList.forEach(item => {
						if(item.stock_num && !app.goods_sku_id){
							app.goods_sku_id = parseInt(item.goods_sku_id)
							return
						}
					})
				}
			}
		},
		methods:{
			addCart(){
				let app = this
				if(this.userId){
					CartApi.add(app.goods.goods_id, app.goods_sku_id, app.number).then(response => {
					  console.log(response)
					  this.$message.success(response.message)
					  this.$emit('addCart')
					}).catch(err => {
					  console.log(err)
					})
				}else{
					this.$message.error('请先登录')
					this.$router.push("/login")
				}
				
			},
			buyNow(){
				let valid = true
				let app = this
				if(app.goods.spec_type == 20 && app.goods_sku_id == 0){
					this.$message.error('请选择仓库')
					return
				}

				app.goods.skuList.forEach(item => {
					if(item.goods_sku_id == app.goods_sku_id){
						if(app.number > item.stock_num){
							valid = false
						}
					}
				})

				if(!valid){
					this.$message.error('库存不足')
				}
				//todo, 跳转到结算页面
				this.$router.push('/settlement?mode=buyNow&goodsId='+app.goods.goods_id+
				'&goodsSkuId='+app.goods_sku_id+'&goodsNum='+app.number)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.information{

	}
</style>
