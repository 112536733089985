<template>

	<div class="carousel">
		<div class="carousel-box">
			<pic-zoom :url="imgurl" :scale="3"></pic-zoom>
		</div>
		<div class="carousel-list">
			<ul>
				<li :class="{active:listIndex==index}" v-for="(item,index) in goods.goods_images" :key="index"
					@mouseenter="changeImage(index)">
					<img :src="item.preview_url" />
				</li>
			</ul>
		</div>
	</div>
</template>
<script>
	import PicZoom from "vue-piczoom"
	export default {
		name: "FooterShow",
		props:{
			goods: Object
		},
		data() {
			return {
				listIndex: 0,
				imgurl: '',
			}
		},
		components: {
			PicZoom
		},
		watch:{
			goods(){
				this.imgurl = this.goods.goods_image
				this.goods.goods_images=this.goods.goods_images.slice(0, 5)
			}
		},
		methods: {
			changeImage(index) {
				this.imgurl = this.goods.goods_images[index].preview_url;
				this.listIndex = index
			}
		}

	}
</script>
<style scoped lang="scss">
	.carousel-box {
		width: 400px;
		height: 400px;
		border: 1px solid #eee;
	}

	.carousel-list {
		width: 400px;
		height: 130px;
	}

	.carousel-list ul {
		width: 400px;
		display: flex;
		justify-content: flex-start;
	}

	.carousel-list ul li {
		overflow: hidden;
		width: 70px;
		height: 70px;
		background-color: white;
		margin: 15px 10.5px 0 0;
		border: 1px solid #eee;

		&.active {
			border: 2px solid $main-color;
		}
	}

	.carousel-list ul li img {
		width: 70px;
		height: 70px;
	}
</style>
